<template>
  <a-tag :color="color">{{ $t(`request_method.${requestMethod}`) }}</a-tag>
</template>

<script>
export default {
  name: 'RequestMethodTag',
  props: {
    requestMethod: {
      type: String,
      required: true
    }
  },
  computed: {
    color () {
      if (this.requestMethod === 'delete') return 'red'
      else if (this.requestMethod === 'put') return 'orange'
      else return 'blue'
    }
  }
}
</script>
