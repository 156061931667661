<template>
  <div>
    <a-page-header
      class="page-header"
      :ghost="false"
      style="margin-bottom: 16px"
    >
      <template #title>
        <a-space size="middle">
          <a-icon type="profile"></a-icon>
          <span>操作日志</span>
        </a-space>
      </template>
      <span>操作日志包含用户、IP 地址、操作时间等。</span>
    </a-page-header>

    <a-card :bordered="false" style="margin-bottom: 16px">
      <list-layout
        :column-default-selected="columnDefaultSelected"
        :columns-all="columnsAll"
        :export-func="exportFunc"
        :get-table-data-func="getTableDataFunc"
        :refresh="refresh"
        :customer-params="customerParams"
        :table-operation-width="0"
        :tools-list="['setting', 'reload', 'search', 'leftTool', 'export']"
        :searchTypeOptions="[
          { label: '用户', value: 'username' },
          { label: 'IP 地址', value: 'remote_address' },
          { label: '用户代理', value: 'user_agent' }
        ]"
      >
        <!-- 工具栏 -->
        <template #leftTool>
          <datetime-range
            time-format="HH:mm"
            @ok="v => (datetimeRange = v)"
          ></datetime-range>
        </template>
        <!-- 表格自定义列 -->
        <template #user="{ slotProps }">
          <a-tooltip v-if="slotProps.record.user" :title="slotProps.record.user.username">
            {{ slotProps.record.user.username }}
          </a-tooltip>
          <span v-else>-</span>
        </template>
        <template #requestMethod="{ slotProps }">
          <request-method-tag :request-method="slotProps.text.toLowerCase()"></request-method-tag>
        </template>
        <template #success="{ slotProps }">
          <boolean-tag
            :value="slotProps.text"
            false-name="失败"
            true-name="成功"
          ></boolean-tag>
        </template>
      </list-layout>
    </a-card>
  </div>
</template>

<script>
import { exportOperationLog, getOperationLogList } from '@/api/log'
import DatetimeRange from '@/components/DatetimeRange'
import ListLayout from '@/components/ListLayout'
import BooleanTag from '@/components/tag/BooleanTag'
import RequestMethodTag from '@/components/tag/RequestMethodTag'
import { requestMethods } from '@/utils/const'

export default {
  name: 'OperationLog',
  components: {
    BooleanTag,
    DatetimeRange,
    ListLayout,
    RequestMethodTag
  },
  data () {
    return {
      getTableDataFunc: getOperationLogList,
      exportFunc: exportOperationLog,
      datetimeRange: [],
      columnsAll: [
        {
          dataIndex: 'user',
          title: '用户',
          width: 150,
          ellipsis: true,
          scopedSlots: {
            customRender: 'user'
          }
        },
        {
          dataIndex: 'remote_address',
          title: 'IP 地址',
          width: 160,
          ellipsis: true,
          scopedSlots: {
            customRender: 'remoteAddress'
          }
        },
        {
          dataIndex: 'created_at',
          title: '操作时间',
          width: 200
        },
        {
          dataIndex: 'request_method',
          title: '请求方式',
          width: 105,
          scopedSlots: {
            customRender: 'requestMethod'
          },
          filters: requestMethods.map(value => {
            return {
              text: this.$t(`request_method.${value}`),
              value
            }
          }),
          filterMultiple: false
        },
        {
          dataIndex: 'request_path',
          title: '请求地址',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'requestPath'
          }
        },
        {
          dataIndex: 'user_agent',
          title: '用户代理',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'userAgent'
          }
        },
        {
          dataIndex: 'message',
          title: '详情',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'message'
          }
        },
        {
          dataIndex: 'success',
          title: '状态',
          width: 100,
          scopedSlots: {
            customRender: 'success'
          },
          filters: [
            {
              text: '成功',
              value: 'true'
            },
            {
              text: '失败',
              value: 'false'
            }
          ],
          filterMultiple: false
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        }
      ],
      columnDefaultSelected: [
        'user',
        'remote_address',
        'created_at',
        'request_method',
        'request_path',
        'user_agent',
        'message',
        'success'
      ],
      refresh: false
    }
  },
  computed: {
    customerParams () {
      const obj = {}
      if (this.datetimeRange.length) {
        obj.datetime_from = this.datetimeRange[0].format('YYYY-MM-DD HH:mm')
        obj.datetime_to = this.datetimeRange[1].format('YYYY-MM-DD HH:mm')
      }
      return obj
    }
  }
}
</script>
